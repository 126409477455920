import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`

    :root {
        --red-cherry: #ff0000;
        --light-gold: hsl(56, 71%, 64%);
        --light-gold-secondary: hsl(56, 88%, 84%);
        --lavender-secondary: hsl(237, 21, 52);
        --neutral-light: hsl(222, 100, 63);
        --border-color: hsl(260, 68, 83);
    }

    *{
        margin: 0;
        padding: 0;
        list-style: none;
        box-sizing: border-box;
        font-family: 'Nunito', sans-serif;
        text-decoration: none;
        font-size: 10px;
    }

    body{
        background-color: var(--neutral-light);
        color: white;
    }

    a{
        color: inherit;
    }

    p {
        color: #000;
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 300;
    }

    h3 {
        color: #000;
        font-size: 2rem;
        line-height: 2.4rem;
        padding-top: 1rem;
        font-weight: 600;
    }


    .secondary-heading {
        font-size: 3.6rem;
        color: #ff0000;
        position: relative;
    }
    .secondary-heading:after {
        position: absolute;
        content:'';
        width: 4rem;
        color: red;
        background-color: red;

    }
    .subheading {
        font-size: 1.6rem;
        font-weight: 400;
        color: 808080;
    }
    @media screen and (max-width: 550px){
        .secondary-heading {
            font-size: 2.8rem;
            color: #ff0000;
            position: relative;
        }
    }
    
`;

export default GlobalStyle;


