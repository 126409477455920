import React from "react";
import Footer from "../components/Footer";
import AllMetalPartContent from "../components/product/AllMetalPartContent";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";

const AllMetalPart = () => {
  return (
    <>
      <Helmet>
        <title>
          Manufacturing Excellence | Precision Metal Parts for Every Need
        </title>
        <meta
          name="description"
          content='Experience manufacturing excellence with our comprehensive range of metal parts. As leaders in the industry, we specialize in producing high-quality, precise metal components for diverse applications. From prototypes to large-scale production, trust us to deliver exceptional craftsmanship, reliability, and abundance to meet your specific requirements."'
        />
      </Helmet>
      <Navbar />
      <AllMetalPartContent />
      <Footer />
    </>
  );
};

export default AllMetalPart;
