import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { FiPhoneCall  } from "react-icons/fi";
import { MdOutlineLocalPostOffice  } from "react-icons/md";
import { ImOffice } from "react-icons/im";

const Footer = () => {
    return (
      <FooterStyled>
        <footer id="footer">
          <div class="footer-holder">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-3">
                  <h3 class="text-uppercase">About us</h3>
                  <p>
                  We believe in TEAM - Together Everyone Achieves More. We are driven by passion of first success of our stake holders. Together we can achieve new heights of growth.
                  </p>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-2">
                  <h3 class="text-uppercase">Links</h3>

                  <ul class="list-unstyled hour-list">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/products">Products</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact </Link>
                    </li>
                  </ul>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4">
                  <h3 class="text-uppercase">Products</h3>
                  <div class="row">
                    <ul class="col-xs-12 col-6 col-sm-6 col-md-5">
                      <li>
                        <Link to="/dyes">Dyes</Link>
                      </li>
                      <li>
                        <Link to="/defence">Defence</Link>
                      </li>
                      <li>
                        <Link to="/tools">Tools</Link>
                      </li>
                      <li>
                        <Link to="/railwayparts">Railwayparts</Link>
                      </li>
                      <li>
                        <Link to="/specialpurpose">Special Purpose</Link>
                      </li>
                      <li>
                        <Link to="/allMetalpart">All Metal Part</Link>
                      </li>
                      <li>
                        <Link to="/autoparts">Auto Parts</Link>
                      </li>
                    </ul>
                    <ul class="col-xs-12 col-6 col-sm-6 col-md-5">
                      <li>
                        <Link to="/deepdraw">Deep Draw</Link>
                      </li>
                      <li>
                        <Link to="/electricallamp">Electrical Lamp</Link>
                      </li>
                      <li>
                        <Link to="/exhaustfan">Exhaust Fan</Link>
                      </li>
                      <li>
                        <Link to="/geyserinnertank">Geyser Inner Tank</Link>
                      </li>
                      <li>
                        <Link to="/inhouseproducts">Inhouse Products</Link>
                      </li>
                      <li>
                        <Link to="/pipesembossingreduce">
                          Pipes Embossing Reduce
                        </Link>
                      </li>
                      <li>
                        <Link to="/specialty">Specialty</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-3">
                  <h3 class="text-uppercase">Get in Touch</h3>

                  <ul class="list-unstyled contact-list">
                    <li>
                      <span>
                        <ImOffice className="u-icon-u" />
                      </span>
                      <address>
                        Sr. No. 12/4 Lagad Colony, Near Lagad Transport, Nanded
                        Gaon, Pune-411041
                      </address>
                    </li>
                    <li>
                      <span>
                        <MdOutlineLocalPostOffice className="u-icon-u" />
                      </span>
                      <Link to="mailto:info@abundancesystems4r.com">
                        info@abundancesystems4r.com
                      </Link>
                    </li>
                    <li>
                      <span>
                        <FiPhoneCall className="u-icon-u" />
                      </span>
                      <Link to="tel:09421037594">+91 9421037594</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-area">
            <div class="container">
              <div class="copyright compyright-mobile d-flex d-sm-flex col-xs-12 col-sm-12 p-2 justify-content-sm-center">
                <div class="col-xs-12 col-sm-6 p-2 text-md-left py-3">
                  {/* <div class="footer-copyright text-center ">
                    © 2021 Copyright: Abundance Systems
                  </div> */}
                  <div class="footer-copyright text-center ">
                  © 2021 Copyright, Abundance Systems. Designed by 
                  <a href="http://www.karmactssystems.com" target='_blank'> Karmacts Systems </a>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 p-2 text-md-left py-3">
                  <div class="footer-copyright text-center ">
                    <ul class="socail-network text-center list-unstyled">
                      <li>
                        <Link to="#" target="_blank">
                          <FaFacebookF className="u-icon-u" />{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <FaLinkedinIn className="u-icon-u" />{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="#" target="_blank">
                          <FaTwitter className="u-icon-u" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </FooterStyled>
    );
}

const FooterStyled = styled.footer`
    padding: 0 9rem;
    background: #111;
    font-size: 1.4rem;
    img {
      width: 40%;
      margin-bottom: 2rem;
    }
    a {
      font-size: 1.4rem;
    }
    a:hover {
      color:#ff0000;
    }
    footer {
      padding-top: 5rem;
    }

  #footer .footer-holder {
      overflow: hidden;
      background: #111;
      color: #acacac;

      h3 {
        font-size: 18px;
        line-height: 20px;
        color: #fff;
        font-weight: 700;
        margin: 0 0 31px;
        padding: 0 0 14px;
        position: relative;
      }
      h3::before {
          position: absolute;
          content: "";
          z-index: -1;
          left: 0;
          top: auto;
          bottom: 0;
          right: auto;
          width: 60px;
          height: 2px;
          z-index: 0;
      }
      p {
        margin: 0 0 12.5px;
        font-size: 1.4rem;
        color: #fff;

      }
      .contact-list address {
          display: inline-block;
          vertical-align: top;
          font-weight: 400;
          font-style: normal;
          margin: 0;
          line-height: 1.85;
          font-size: 1.4rem;
      }
      .hour-list li a {
        font-size: 1.4rem;
      }
    } 
    .contact-list li {
      display: flex;
      flex-direction: row;
      margin: 1rem 0;

      .u-icon-u {
        font-size: 2rem;
        margin-right: 1rem;
      }

    }

    #footer .footer-area {
        padding: 10px 0 14px;
        overflow: hidden;
        background: #000;
    }

    #footer .socail-network {
      float: right;
      text-align: right;
      color: #bebebe;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 550px){
        text-align: center;
        float: none;
      }

      .u-icon-u {
        font-size: 1.6rem;
      }

      
    }
    #footer .socail-network li {
        margin: 0 0 0 39px;
    }
    #footer .footer-area p {
        margin: 0;
    }
    .copyright p{
      color: #bebebe;
      font-size: 1.4rem;
    }
    .footer-copyright  {
      font-size: 1.6rem;
      a{
        font-size: 1.6rem;
      }
    }

    @media screen and (max-width: 1347px){
      padding: 5rem 14rem;
    }
    @media screen and (max-width: 1186px){
        padding: 5rem 8rem;
    }
    @media screen and (max-width: 990px){
        padding: 5rem 4rem;
    }
    @media screen and (max-width: 550px){
      .compyright-mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
      }
    }

    .footer-con {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .logo-on {
        display: flex;
        align-items: center; 
        img {
            width: 90px;
        }
    }

    .bottom-nav {
        display: flex;
        justify-content: space-between; 

    }
`;

export default Footer
