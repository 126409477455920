import React from 'react'
import defence from '../../img/defence.jpg'
import Common from './Common';
import './Product.css'

const DefenceContent = () => {
    return (
        < >
            <Common 
            title='Defence Parts Manufacturing'
            description='All kinds of defence metal part tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={defence}
            />
        </>
    )
}
export default DefenceContent
