import React from 'react'
import { BiBullseye  } from "react-icons/bi";
import { GiBullseye  } from "react-icons/gi";
import './AboutContent.css'
import rahulthopate from '../img/rahulthopate.jpeg'
import raginithopate from '../img/raginithopate.jpeg'

const AboutContent = () => {
    return (
      <>
        <div id="main" className="about-container">
          <section class="abt-sec style2 container">
            <div className="card-container">
              <div className="card-left">
                <h2 className="secondary-heading">About Us</h2>
                <p>
                  Welcome to Abundance Systems, your number one source for all
                  things in metal spinning. We're dedicated to providing you the
                  very best of metal spinning, with an emphasis on high quality,
                  accurate precision and abundance.
                </p>
                <div className="u-col">
                  <div className="u-layout-row">
                    <div className="u-align-center u-container-style">
                      <div className="u-container-layout u-align-top u-container-layout-2">
                        <span className="u-icon u-icon-circle">
                          <i>
                            <BiBullseye className="u-icon-u" />
                          </i>
                        </span>
                        <h3 className="u-text u-text-4">Our Vision</h3>
                        <p className="u-text u-text-grey-50 u-text-5">
                          Become a master in metal spinning.
                        </p>
                      </div>
                    </div>
                    <div className="u-align-center u-container-style">
                      <div className="u-container-layout u-valign-top u-container-layout-3">
                        <span className="u-icon u-icon-circle">
                          <GiBullseye className="u-icon-u" />
                        </span>
                        <h3 className="u-text u-text-6">Our Mission</h3>
                        <p className="u-text u-text-grey-50 u-text-7">
                          On the path to become a master in metal spinning.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-right d-flex flex-xl-row">
                <div className="img-left ">
                  <img src={raginithopate} alt="card" />
                  <h3>Mrs. Ragini Thopate</h3>
                  <p>Director</p>
                </div>
                <div className="img-right">
                  <img src={rahulthopate} alt="card" />
                  <h3>Mr. Rahul Thopate</h3>
                  <p>Manager</p>
                </div>
              </div>
            </div>
          </section>
          <section class="abt-sec  style2 container">
            <div class="row abt-sec-second">
              <div class="col-xs-12">
                <h2 class="main-heading  text-uppercase">COMPANY</h2>
                <p>
                  Founded in 2005 by Mr. & Mrs. Ragini Rahul Thopate, Abundance
                  Systems has come a long way from its beginnings in Pune,
                  Maharashtra, India. When Mr & Mrs. Thopate first started out,
                  their passion for making abundance drove them to start the
                  company so that Abundance Systems can offer you world class
                  metal spinning products. We now serve customers all over India
                  and other countries, and are thrilled that we're able to turn
                  our passion into our vision.
                </p>
                <p>
                  We hope you enjoy our products as much as we enjoy offering
                  them to you. If you have any questions or comments, please
                  don't hesitate to contact us.
                </p>
              </div>
            </div>
          </section>
        </div>
      </>
    );
}

export default AboutContent
