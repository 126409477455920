import React from 'react'
import styled from 'styled-components'
import SecondaryButton from './SecondaryButton';
import spinning from '../img/spinning.png';
import def from '../img/def.png';
import dyes from '../img/dyes.png';
import autopart from '../img/autopart.png';
import { Fade } from 'react-reveal';

const HeaderContent = () => {
    return (
        <HeaderContentStyled>
            <Fade left>
                <div className="left-content">
                    <div className="left-text-container">
                        <h1>Manufacturing of any Metal Parts</h1>
                        <p className="white">You give us any metal part we produce and customize them as per your requirements. We believe in first success of our stake holders then ours.</p>
                        <SecondaryButton name={'Know More'} />
                    </div>
                </div>
            </Fade>
            <Fade right>
                <div className="right-content">
                    <img src={spinning} alt="Spinning" className="spinningworks" />
                    <img src={def} alt="defence" className="def1" />
                    <img src={dyes} alt="dyes" className="dyes1" />
                    <img src={autopart} alt="autopart" className="autopart1" />
                </div>
            </Fade>
            
        </HeaderContentStyled>
    )
}

const HeaderContentStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 7rem;
    h1{
        padding-bottom: 1rem !important;
    }
    @media screen and (max-width: 700px){
        grid-template-columns: repeat(1, 1fr);
        
    }
    .left-content{
        display: flex;
        align-items: center;
        padding-right: 3rem;
        .white{
            color:white;
        }
        h1{
            font-size: 4rem;
            font-weight: 900;
            @media screen and (max-width: 700px){
                font-size: 3.6rem;
                padding-top: 2rem;
            }
        }
        .white{
            padding: 1.4rem 0;
            line-height: 2.4rem;
            color: #fff;
            font-weight: 600;
        }
    }
     
         
 

    .right-content {
        position: relative;
        display: flex;
        justify-content: center;
        .spinningworks {
            width: 80%;
        }
        .def1 {
            position: absolute;
            bottom: 10%;
            right: 10%;
            left: auto;
            animation: move 20s infinite;
            transition: all .4s ease-in-out;
            
            
        }
        .dyes1 {
            position: absolute;
            top: 5%;
            right: 10%;
            left: auto;
            width: 20%;
            transition: all .4s ease-in-out;
            animation: move 8s infinite;
            animation-delay: .5s; 
            transition: all .4s ease-in-out;
        }
        .autopart1 {
            position: absolute;
            bottom: 15%;
            left: 10%;
            transition: all .4s ease-in-out;
            animation: move 5s infinite;
            transition: all .4s ease-in-out;
        }
    }

    //Header Animation

    .dyes1 {
        @keyframes move{
            0%{
                transform: translateY(0) rotate(0deg) scale(1) translateX(0);
            }
            50%{
                transform: translateY(-10px) rotate(20deg) scale(1.1) translateX(10px);
            }
            100%{
                transform: translateY(0) rotate(0deg) scale(1) translateX(0);
            }
        }
        @keyframes move2{
            0%{
                transform: translateY(0) rotate(0deg) scale(1) translateX(0);
            }
            50%{
                transform: translateY(-10px) rotate(60deg) scale(1.1) translateX(10px);
            }
            100%{
                transform: translateY(0) rotate(0deg) scale(1) translateX(0);
            }
        }
    }
`;

export default HeaderContent
