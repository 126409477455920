import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import FabricationContent from "../components/product/FabricationContent";
import { Helmet } from "react-helmet";

const Fabrication = () => {
  return (
    <>
      <Helmet>
        <title>
          Custom Fabrication Work | Precision Craftsmanship and Versatile
          Solutions
        </title>
        <meta
          name="description"
          content="Discover our custom fabrication work services, known for precision craftsmanship and versatile solutions. As experts in fabrication, we specialize in creating tailored metal and structural components, providing reliable and high-quality solutions for diverse industries. Trust our expertise to deliver exceptional results, meticulous attention to detail, and a wide range of fabrication options to meet your specific project requirements."
        />
      </Helmet>
      <Navbar />
      <FabricationContent />
      <Footer />
    </>
  );
};

export default Fabrication;
