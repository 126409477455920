import React from 'react'
import { Route, Routes, Link } from "react-router-dom";
import Home from './Home';
import About from './pages/About';
import Products from './pages/Products';
import Contact from './pages/Contact';
import Dyes from './pages/Dyes';
import Defence from './pages/Defence';
import Tools from './pages/Tools';
import RailwayParts from './pages/RailwayParts';
import SpecialPurposeDevelopment from './pages/SpecialPurposeDevelopment';
import AllMetalPart from './pages/AllMetalPart';
import AutoParts from './pages/AutoParts';
import DeepDraw from './pages/DeepDraw';
import ElectricalLamp from './pages/ElectricalLamp';
import ExhaustFan from './pages/ExhaustFan';
import GeyserInnertank from './pages/GeyserInnertank';
import InhouseProducts from './pages/InhouseProducts';
import PipesembossingReduce from './pages/PipesembossingReduce';
import Specialty from './pages/Specialty';
import Fabrication from './pages/Fabrication';
import Spinning from './pages/Spinning';
import Landing from './pages/landing/Landing';
import Landingcore from './pages/landing/Landingcore';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/abundance-systems" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/dyes" element={<Dyes />} />
        <Route path="/defence" element={<Defence />} />
        <Route path="/tools" element={<Tools />} />
        <Route path="/railwayparts" element={<RailwayParts />} />
        <Route path="/specialpurpose" element={<SpecialPurposeDevelopment />} />
        <Route path="/allMetalpart" element={<AllMetalPart />} />
        <Route path="/autoparts" element={<AutoParts />} />
        <Route path="/deepdraw" element={<DeepDraw />} />
        <Route path="/electricallamp" element={<ElectricalLamp />} />
        <Route path="/exhaustfan" element={<ExhaustFan />} />
        <Route path="/fabrication" element={<Fabrication />} />
        <Route path="/spinning" element={<Spinning />} />
        <Route path="/geyserinnertank" element={<GeyserInnertank />} />
        <Route path="/inhouseproducts" element={<InhouseProducts />} />
        <Route path="/pipesembossingreduce" element={<PipesembossingReduce />} />
        <Route path="/specialty" element={<Specialty />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/corebusiness" element={<Landingcore />} />
      </Routes>
    </div>
  );
}



export default App;
