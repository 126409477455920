import React, {useState} from 'react'
import './Product.css'
import Common from './Common';
import autoParts from '../../img/autoParts.jpg';


const AutoPartsContent = () => {
    return (<>
            
        <Common 
            title='Welcome to auto part page'
            description='All types of Auto Parts tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...'
            imgsrc={autoParts}
         />
        
        </>
    )
}

export default AutoPartsContent
