import React from 'react'
import Common from './Common';
import RailwayParts from '../../img/RailwayParts.jpg'
import './Product.css'

const RailwayPartsContent = () => {
    return (
        <>
            <Common 
            title='Railway Parts Manufacturing'
            description='We do Railway Parts Manufacturing and tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={RailwayParts}
            />
        </>
    )
}

export default RailwayPartsContent
