import React from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ToolsContent from '../components/product/ToolsContent';
import { Helmet } from 'react-helmet';

const Tools = () => {
    return (
        
        <>
        <Helmet>
        <title>
        Spinning Tools Manufacturing | High-Quality Tools for Superior Metal Spinning
        </title>
        <meta
          name="description"
          content="Experience our spinning tools manufacturing services, delivering high-quality tools for superior metal spinning. With our expertise, we specialize in manufacturing precision spinning tools that ensure optimal performance, efficiency, and exceptional results. Trust our commitment to craftsmanship and quality to provide reliable spinning tools that meet your specific requirements."
        />
      </Helmet>
        <Navbar />
       <ToolsContent />
       <Footer />
       </>
    )
      
}
export default Tools
