import React, { useState } from "react";
import "./Product.css";
import { Helmet } from "react-helmet";

const Common = (props) => {
  return (
    <>
      <div className="dyes-container">
        <div>
          <section id="hero">
            <div class="grid">
              <div class="column-60">
                <h1 className="secondary-heading">{props.title}</h1>
                <p id="description">{props.description}</p>
              </div>
              <div class="column-40">
                <div class="image-div">
                  <img
                    id="hero-img"
                    src={props.imgsrc}
                    alt="Remedi mobile app"
                    width="270"
                    height="497"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Common;
