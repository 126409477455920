import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import PipesembossingReduceContent from '../components/product/PipesembossingReduceContent'
import { Helmet } from 'react-helmet'

const PipesembossingReduce = () => {
    return (
        <>
        <Helmet>
        <title>
        Pipes Embossing and Reduce Services | Precision Enhancement and Size Optimization
        </title>
        <meta
          name="description"
          content="Experience our professional pipes embossing and reduce services, enhancing precision and optimizing sizes. With our expertise, we specialize in embossing techniques that improve pipe functionality, while also reducing pipe diameters to meet specific project requirements. Trust our precision craftsmanship to deliver efficient solutions and superior pipe performance."
        />
      </Helmet>
            <Navbar />
            <PipesembossingReduceContent />
            <Footer />
        </>
    )
}

export default PipesembossingReduce
