import React, {useState} from 'react'
import styled from 'styled-components'
import { InnerLayout } from '../styles/Layout';
import SecondaryButton from './SecondaryButton';
import { FiPhoneCall  } from "react-icons/fi";
import { MdOutlineLocalPostOffice  } from "react-icons/md";
import { ImOffice } from "react-icons/im";

const Contactus = () => {

    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email:  "",
        address: "",
        message: "",
    });

    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value = event.target.value;

        setUserData({ ...userData, [name]: value });
    };
    //connect with firebase
    const submitData = async (event) => {
        event.preventDefault();
        const { firstName, lastName, phone, email, address, message } = userData;

        if(firstName && lastName && phone && email && address && message) {

        
            const res = fetch(
                "firebaseurl",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    firstName,
                    lastName,
                    phone,
                    email,
                    address,
                    message,
                }),
            }
            );
            if (res) {
                setUserData({
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email:  "",
                    address: "",
                    message: "",
                })
                alert("Data Stored");
            } else {
            alert("Please fill the data")
            }
        } else {
            alert("Please fill the data")
            }
    };



        return (
            <ContactusStyled>
                <InnerLayout>
                    <div className="contact-container">
                        <div className="contact-left">
                            <div className="contact-leftside col-lg-12">
                                <h3 className="subheading">Contact Us</h3>
                                <h1 className="secondary-heading">Connect with Us</h1>
                                <p className="main-hero-para">To get best in class products get in touch with us.</p>
                                <span>
                                <FiPhoneCall className='u-icon-u'/> <p>+91 94210 37594</p>
                                </span>
                                <span>
                                <MdOutlineLocalPostOffice className='u-icon-u'/> <p>info@abundancesystems4r.com</p>
                                </span>
                                <span>
                                <ImOffice className='u-icon-u'/> <p>Lagad Colony, Nanded Phata,<br/> Sinhgad Road, Pune-411041</p>
                                </span>
                            </div>
                        </div>
                        <div className="contact-right">
                        <form method="POST">
                            <div className="contact-row">
                                <div className="col-12 col-lg-12 contact-input-field">
                                    <input type="text" name="firstName" id="" className="form-control" placeholder="First Name"
                                    value={userData.firstName}
                                    onChange={postUserData} />
                                </div>
                                <div className="col-12 col-lg-12 contact-input-field">
                                    <input type="text" name="lastName" id="" className="form-control" placeholder="Last Name"
                                    value={userData.lastName}
                                    onChange={postUserData} />
                                </div>
                        
                            
                                <div className="col-lg-12 contact-input-field">
                                    <input type="text" name="phone" id="" className="form-control" placeholder="Phone Number"
                                    value={userData.phone}
                                    onChange={postUserData} />
                                </div>
                                <div className="col-lg-12 contact-input-field">
                                    <input type="text" name="email" id="" className="form-control" placeholder="Your Email"
                                    value={userData.email}
                                    onChange={postUserData} />
                                </div>
                            </div>
                            <div className="row-address">
                                <div className="contact-input-field">
                                    <input type="text" name="address" id="" className="form-control" placeholder="Add Address" 
                                    value={userData.address}
                                    onChange={postUserData} 
                                    />
                                </div>
                            </div>
                            <div className="row-message">
                                <div className="contact-input-field">
                                    <textarea type="text-area" name="message" id="" className="form-control" placeholder="Enter your message"
                                    value={userData.message}
                                    onChange={postUserData}  />
                                </div>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                <label>
                                <p>I agree that the Abundance Systems may contact me at the email address or phone number above</p> 
                                
                                </label>
                                </div>
                                <button type="submit" className="input-form-button" onClick={submitData} >Submit</button> 
                        </form>
                    </div>
                </div>
            </InnerLayout>
        </ContactusStyled>
    )
}

const ContactusStyled = styled.section`
    margin-top: 5rem;
    padding: 2rem 1rem;
    .contact-container {    
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: center;
        margin: 2rem 0rem;
        padding: 5rem 2rem;
        position: relative;
        box-shadow: 0px 25px 50px rgba(22, 25, 79, 0.05);
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.1);
        overflow: hidden;
        justify-content: center;
        border-top: 1px solid rgba(255, 255, 255, 0.2);

        @media screen and (max-width: 550px){
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            padding: 2rem 4rem;
            
            padding: 0 1rem;
        }

        @media screen and (max-width: 700px){
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            margin-top: 2rem;
        }
        
    }
    
    

    .contact-left {
        
            span {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            
            p {
                margin-left: 1rem;
            }
            
            .u-icon-u {
                font-size: 5rem;
                color: red;
                width: 3rem;
                padding:
            }
        }
    
    }
    .contact-right {
        height: 100%;
        width: 100%;
        padding: 2rem;
        form {
            overflow: hidden;
        }

        form .form-control {
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        height: 4rem;
        border-radius: 0.5rem;
        font-size: 1.6rem;
        padding-left: 1rem;
        font-weight: lighter;
        }
        form .form-check-input {
        width: 2rem;
        height: 2rem;
    }
    
    @media screen and (max-width: 550px){
        .contact-leftside {
            padding: 5rem 5rem; 
        }
    }
    .contact-row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media screen and (max-width: 550px){
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            
        }
    }
    .row-address {
        display: flex;
        flex-direction: column;
    }

    .contact-input-field {
        padding: 1rem 1rem;
        width: 100%;
    }

    input, select {
        width:100%;
    }
    textarea {
        width: 100%;
        height: 100px;
    }

    .img-contact {
        width: 80%;
    }
    .form-check {
        display: flex;
        flex-direction: row;
        margin-left: 1rem;  
        padding-bottom: 1rem;      
    }

    .form-check p {
        font-size: 1.5rem;
        padding-left: 1rem;
    }

    form .input-form-button {
        cursor: pointer;
        padding: 1rem 3rem;
        border-radius: 2rem;
        background-color: var(--light-gold);
        outline: none;
        font-size: 1.8rem;
        font-weight: 600;
        font-family: inherit;
        border: lighter;

        :hover {
            padding: 0.9rem 2.8rem;
            transition: all 0.3s ease-out;
            background-color: red;
            color: #fff;
            border-radius: 2rem;
            border: 2px solid var(--light-gold-secondary);
        }

        @media screen and (max-width: 960px) {
            display: block;
        }
    }
`;

export default Contactus
