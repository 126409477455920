import React from 'react'
import Navbar from '../components/Navbar'
import ExhaustFanContent from '../components/product/ExhaustFanContent'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'


const ExhaustFan = () => {
    return (
        <>
        <Helmet>
        <title>
        Exhaust Fan Parts Manufacturing | Superior Quality and Efficient Solutions
        </title>
        <meta
          name="description"
          content="Explore our exhaust fan parts manufacturing services, offering superior quality and efficient solutions. As trusted manufacturers, we specialize in producing precision components for exhaust fans, ensuring optimal performance, durability, and quiet operation. Count on our expertise to deliver reliable craftsmanship and tailored solutions for all your exhaust fan manufacturing requirements."
        />
      </Helmet>
        <Navbar />
        <ExhaustFanContent />
        <Footer />
        </>
    )
}

export default ExhaustFan
