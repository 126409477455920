import React from 'react'
import Common from './Common';
import speciality from '../../img/speciality.jpg'
import './Product.css'


const SpecialtyContent = () => {
    return (
        <>
            <Common 
            title='Specialty in Metal Spinning Tools and Job Work'
            description='We have Specialty in Metal Spinning Tools and Job Work of metal parts and tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={speciality}
            />
        </>
    )
}

export default SpecialtyContent
 