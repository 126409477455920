import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./style2.css";
import logo from "./../../img/logo.svg";
import product from "./../../img/l2.png";
import product1 from "./../../img/landing2.png";
import product2 from "./../../img/landing3.png";
import product3 from "./../../img/landing4.png";
import product4 from "./../../img/landing5.png";
import exhaust from "./../../img/exhaustFan.jpg";
import autoparts from "./../../img/autoParts.jpg";
import fabrication from "./../../img/fabrication.jpg";
import special from "./../../img/speciality.jpg";

import Contactus from "../../components/Contactus";
import { FiArrowRight } from "react-icons/fi";
import SimpleSlider, { ContactUs } from "./SimpleSlider";
import { useState } from "react";
import { message } from "antd";

export default function Landing() {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hghnbif",
        "template_zyvrn7w",
        form.current,
        "n7Stmp1rYvd38e1li"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("sendd");
          message.success("Email Send Successfull");
          setFormData({
            user_name: "",
            user_email: "",
            user_phone: "",

            message: "",
          });
        },
        (error) => {
          console.log(error.text);
          message.error("Email Send Unsuccessfull");

          setFormData({
            user_name: "",
            user_email: "",
            user_phone: "",

            message: "",
          });
        }
      );
  };

  return (
    // <div className="landing">
    //       <img className="landing-logo" src={logo} alt="" />

    //   <div className="landing-con">
    //     <div className="landing-left">
    //       <h1 >ABUNDANCE</h1>
    //       <p>
    //         Lorem ipsum dolor, sit amet consectetur adipisicing elit. Soluta, ex
    //         impedit voluptatem ipsam natus eaque! Pariatur voluptas perspiciatis
    //         velit voluptatum eligendi assumenda consequuntur!
    //       </p>
    //       <button className="visit-us">
    //         <span>

    //         Visit Us
    //         </span>
    //       <FiArrowRight style={{fontSize:"20px"}}/>
    //       </button>
    //     </div>

    //   </div>
    //   <div className="landing-section">
    //     <div className="landing-card">
    //       <img src={product} alt="" />
    //       <div>
    //         <h1>Product 1</h1>
    //         <p>
    //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
    //           adipisci ea, reprehenderit harum ratione ipsum! Est dicta dolore
    //           ea cum, earum, dignissimos laborum explicabo aspernatur asperiores
    //           architecto aperiam nihil eveniet cupiditate aliquam nulla dolorum.
    //         </p>
    //       </div>
    //     </div>
    //     <div className="landing-card">
    //     <div>
    //         <h1>Product 4</h1>
    //         <p>
    //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
    //           adipisci ea, reprehenderit harum ratione ipsum! Est dicta dolore
    //           ea cum, earum, dignissimos laborum explicabo aspernatur asperiores
    //           architecto aperiam nihil eveniet cupiditate aliquam nulla dolorum.
    //         </p>
    //       </div>
    //       <img src={product4} alt="" />

    //     </div>
    //     <div className="landing-card">
    //       <img src={product1} alt="" />
    //       <div>
    //         <h1>Product 2</h1>
    //         <p>
    //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
    //           adipisci ea, reprehenderit harum ratione ipsum! Est dicta dolore
    //           ea cum, earum, dignissimos laborum explicabo aspernatur asperiores
    //           architecto aperiam nihil eveniet cupiditate aliquam nulla dolorum.
    //         </p>
    //       </div>
    //     </div>
    //     <div className="landing-card">
    //     <div>
    //         <h1>Product 3</h1>
    //         <p>
    //           Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
    //           adipisci ea, reprehenderit harum ratione ipsum! Est dicta dolore
    //           ea cum, earum, dignissimos laborum explicabo aspernatur asperiores
    //           architecto aperiam nihil eveniet cupiditate aliquam nulla dolorum.
    //         </p>
    //       </div>
    //       <img src={product3} alt="" />

    //     </div>
    //   <Contactus/>
    //   </div>
    //
    <>
      <section
        id="slider"
        style={{ paddingTop: "0px" }}
        class="section slider landing-section">
        <div class="slide--item bg-theme">
          <div class="container landing-con">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div
                  class="slide--logo mt-100 hidden-xs wow fadeInUp"
                  data-wow-duration="1s">
                  {/* <img src="assets/images/logo/logo-light.png" alt="logo hero"/> */}
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-xs-12 col-sm-6 col-md-6 pt-100 wow fadeInUp"
                data-wow-duration="1s">
                <div class="slide--headline">
                  <h1>Illuminate Your Space with Exquisite Metal DIYAs</h1>
                </div>
                <div class="slide--bio">
                  Light up your home, office, or sacred place with these
                  exquisite diyas, meticulously crafted by skilled artisans.
                  Made from high-quality metals, each diya showcases intricate
                  patterns and exquisite detailing, reflecting the rich cultural
                  heritage of India.
                </div>
              </div>
              <img
                class="img-responsive pull-right landing-top-logo"
                src={product}
                alt="screens"
              />
              {/* <div class="col-xs-12 col-sm-6 col-md-4 col-md-offset-2 wow fadeInUp" data-wow-duration="1s">
						</div> */}
            </div>
          </div>
        </div>
      </section>
      <section
        id="feature2"
        class="section feature feature-2 text-center bg-white landing-section">
        <div class="container">
          <div class="rows clearfix">
            <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-80 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title">Shine Bright with Metal Diyas</h2>
                <p class="heading--desc">6 Reasons to Choose Elegance</p>
              </div>
            </div>
          </div>
          <div class="rows mb-60">
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-users"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Durability</h3>
                  <p>
                    Metal diyas offer exceptional durability compared to other
                    materials. Crafted from high-quality metals, these diyas are
                    built to last, ensuring you can enjoy their beauty and
                    radiance for years to come.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-cog"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product1} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Exquisite Craftsmanship</h3>
                  <p>
                    Metal diyas are meticulously handcrafted by skilled
                    artisans, showcasing intricate designs and fine detailing.
                    Each diya is a testament to the artistry and expertise of
                    these craftsmen, adding a touch of elegance and
                    sophistication to your space.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-lock"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product3} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Timeless Appeal</h3>
                  <p>
                    With their classic designs and timeless appeal, metal diyas
                    never go out of style. Whether you prefer traditional or
                    contemporary decor, these diyas effortlessly complement any
                    setting, creating a captivating ambiance that stands the
                    test of time.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="rows">
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product4} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Cultural Significance</h3>
                  <p>
                    Metal diyas hold immense cultural significance, particularly
                    in Indian traditions and festivals. Lighting diyas
                    symbolizes the triumph of light over darkness and is
                    believed to bring prosperity, positivity, and blessings into
                    the home.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product2} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Versatility</h3>
                  <p>
                    Metal diyas offer versatility in terms of placement and
                    usage. They can be placed on floors, tables, or hung on
                    walls, allowing you to decorate various spaces as per your
                    preference. Additionally, they can be used for both indoor
                    and outdoor lighting, enhancing the beauty of any occasion
                    or celebration.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Ambient Lighting </h3>
                  <p>
                    The flickering glow of a metal diya creates a warm and
                    soothing atmosphere, making it perfect for creating a cozy
                    and intimate ambiance. Whether you're hosting a dinner
                    party, meditating, or enjoying a quiet evening, the soft,
                    gentle illumination of a metal diya adds a touch of
                    tranquility and serenity to your surroundings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="newsletter"
        class="section newsletter text-center bg-theme1 landing-section">
        <div class="container">
          <div class="rows clearfix" style={{ marginBottom: "40px" }}>
            <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-60 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title text-white">Contact us</h2>
              </div>
            </div>
          </div>
          <div class="rows">
            <div className="landing-form">
              <form class="" ref={form} onSubmit={sendEmail}>
                <div class="input-group">
                  <div className="m-landing">
                    <input
                      required
                      value={formData.user_name}
                      onChange={handleInputChange}
                      style={{ fontSize: "14px", marginBottom: "0px" }}
                      type="text"
                      name="user_name"
                      class="form-control input-landing"
                      placeholder="Enter Your Name"
                    />
                  </div>
                  <div>
                    <input
                      required
                      value={formData.user_email}
                      onChange={handleInputChange}
                      style={{ fontSize: "14px", marginBottom: "30px" }}
                      type="email"
                      name="user_email"
                      class="form-control input-landing"
                      placeholder="E-mail address"
                    />
                  </div>
                </div>

                <div class="input-group">
                  <input
                    required
                    value={formData.user_phone}
                    onChange={handleInputChange}
                    style={{ fontSize: "14px", marginBottom: "30px" }}
                    type="number"
                    name="user_phone"
                    class="form-control input-landing"
                    placeholder="Enter Your Phone No"
                  />
                </div>

                <div class="input-group">
                  <input
                    required
                    value={formData.message}
                    onChange={handleInputChange}
                    style={{ fontSize: "14px", marginBottom: "30px" }}
                    type="text"
                    name="message"
                    class="form-control input-landing"
                    placeholder="Query"
                  />
                </div>

                {/* <div class="input-group">
								<input style={{fontSize:"14px",marginBottom:'30px'}} type="email" class="form-control input-landing" placeholder="E-mail address"/>
							
							</div> */}
                <button type="submit" value="Send" className="submit-landing">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section
        id="reviews"
        class="section reviews bg-white landing-section"
        style={{ textAlign: "center" }}>
        <div style={{ width: "100%" }} class=" for-landing-review">
          <div class="rows clearfix">
            <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-60 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title">Glowing Testimonials</h2>
                <p>Hear What Our Customers Say About Metal Diyas</p>
              </div>
            </div>
          </div>
          <div class="rows landing-r-width">
            <div class=" landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      Absolutely delighted with the metal diyas I purchased from
                      this store! The craftsmanship is impeccable, and the
                      intricate designs add a touch of elegance to my home. The
                      warm glow of these diyas creates a serene atmosphere
                      during festive celebrations. Highly recommended!
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Aarav Gupta</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star-half-full"></i>
                      <i class=" fa fa-star-o"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      I couldn't be happier with my purchase of metal diyas. The
                      durability of these diyas is impressive, and the fine
                      detailing reflects the true artistry behind them. They
                      have become a centerpiece of my home decor, spreading
                      positive energy and radiance. Thank you for offering such
                      exquisite products!
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Naina Patel</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      As an ardent lover of Indian traditions, I am delighted
                      with the authenticity of these metal diyas. They not only
                      bring cultural significance to my home but also serve as a
                      stunning visual element. The craftsmanship is outstanding,
                      and the ambient lighting creates a tranquil environment. A
                      must-have for anyone seeking a touch of tradition!
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Arjun Sharma</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star-half-full"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      These metal diyas have added a magical touch to my space.
                      The versatility in placement options allows me to create
                      beautiful setups for different occasions. Whether it's a
                      festive celebration or a peaceful evening of meditation,
                      the gentle glow of these diyas creates a calming and
                      serene atmosphere. I am truly impressed with their beauty
                      and craftsmanship!
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Ananya Mehta</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star-half-full"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ContactUs/> */}

      <div className="landing-extra">
        <div className="landing-extra-card">
          <img src={exhaust} alt="" />
          <div>
            <h2>Exhaust Fan Parts Manufacturing</h2>
            <p>
              Exhaust Fan Parts tooling, cutting, dies, bending dies, draw dies,
              deep draw, and spinning etc...
            </p>
            <div></div>
          </div>
        </div>
        <div className="landing-extra-card">
          <img src={autoparts} alt="" />
          <div>
            <h2>Auto Parts</h2>
            <p>
              All types of Auto Parts tooling, cutting, dies, bending dies, draw
              dies, deep draw, and spinning etc...
            </p>
          </div>
        </div>
        <div className="landing-extra-card">
          <img src={fabrication} alt="" />
          <div>
            <h2>Fabrication Work</h2>
            <p>
              All types of fabrication work, cutting, dies, bending dies, draw
              dies, deep draw, and spinning etc...
            </p>
          </div>
        </div>
        <div className="landing-extra-card">
          <img src={special} alt="" />
          <div>
            <h2>Specialty in Metal Spinning Tools and Job Work</h2>
            <p>
              We have Specialty in Metal Spinning Tools and Job Work of metal
              parts and tooling, cutting, dies, bending dies, draw dies, deep
              draw, and spinning etc...
            </p>
          </div>
        </div>
      </div>
      <button className="landing-btns vissit">Visit Our Site</button>
    </>
  );
}
