import React from "react";
import styled from "styled-components";
import CardSection from "./components/CardSection";
import Contactus from "./components/Contactus";
import Footer from "./components/Footer";
import Header from "./components/Header";
import WhatweDo from "./components/WhatweDo";
import { OuterLayout } from "./styles/Layout";
import { Fade } from "react-reveal";
import MediaSection from "./components/MediaSection";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <div>
      <Helmet>
        <title>Abundance System | Precision Metal Spinning Experts | Unmatched Quality and Abundance</title>
        <meta name="description" content="Discover the leading provider of metal spinning services, offering unparalleled precision, exceptional quality, and abundant options. Trust our expertise to bring your metal projects to life with impeccable craftsmanship and superior results." />
      </Helmet>
      <Header />
      <OuterLayout>
        <MainStyled>
          <Fade>
            <CardSection />
            <WhatweDo />
            <MediaSection />
            <Contactus />
          </Fade>
        </MainStyled>
      </OuterLayout>
      <Fade>
        <Footer />
      </Fade>
    </div>
  );
};

const MainStyled = styled.main``;

export default Home;
