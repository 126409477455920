import React from 'react'
import { Bounce } from 'react-reveal';
import styled from 'styled-components'
import { InnerLayout } from '../styles/Layout';
import AnimationButton from './AnimationButton';

const WhatweDo = () => {
    return (
        <WhatweDoStyled>
            <InnerLayout>
            <div className="wht-we-do-container">
              <h2 className="secondary-heading">
                What We Do?
              </h2>
                <div className="wht-we-do-row">
                    <div className="chart-left">
                        <div className="wh-we-do">
                            <h3>Manufacturing</h3>
                            <p>We do manufacturing of all metal parts like tooling, cutting, dies, bending dies, draw dies, deep draw dies, and spinning etc...</p>
                        </div>
                    </div>
                    <div className="chart-right">
                        <h3>Consulting</h3>
                        <p>We do consulting for the manufacturing units and companys.</p>
                    </div>
                    
                </div>
                <div className="chart-last">
                        <h3>Industry Solution</h3>
                        <p>We provide manufacturing related customized solutions.</p>
                </div>
                <div className="button-div-wh">
                <Bounce left>
                    <AnimationButton name={'Learn More'} />
                </Bounce>
                </div>
                
            </div>
            </InnerLayout>
        </WhatweDoStyled>
    )
}

const WhatweDoStyled = styled.section`
    .wht-we-do-container {
        position: relative;
        box-shadow: 0px 25px 50px rgba(22, 25, 79, 0.05);
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.1);
        overflow: hidden;
        justify-content: center;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding: 2rem 4rem;
    }
    
    .wht-we-do-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        align-items: center;
        justify-content: center;
        grid-gap: 2rem;
        margin-bottom: 2rem;
        @media screen and (max-width: 990px){
            grid-template-columns: repeat(1, 1fr);
        }
    }
    .wht-we-do-row:nth-last-child(2n) {
        color: lime;
    }
    .chart-last {
        justify-content: center;
        padding: 2rem 20rem;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 990px){
            display: block;
            padding: 0;
        }
    }
    .button-div-wh {
        justify-content: center;
        align-item: center;
        display: flex;
        @media screen and (max-width: 990px){
            margin-top: 2rem;
        }
        
    }
`;

export default WhatweDo
