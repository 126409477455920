import React from 'react'
import styled from 'styled-components'

const PrimaryButton = ({name}) => {
    return (
        <ButtonStyled>
            {name}
        </ButtonStyled>
    )
}

const ButtonStyled = styled.button`
    cursor: pointer;
    padding: 0.5rem 0.5rem;
    border-radius: 1rem;
    background-color: var(--light-gold-secondary);
    outline: none;
    font-size: 1.6rem;
    font-weight: 600;
    font-family: inherit;
    border: lighter;
    width: 130px;

    :hover {
        padding: 0.6rem 0.6rem;
        transition: all 0.3s ease-out;
        background-color: transparent;
        color: #fff;
        border-radius: 1rem;
        border: 2px solid var(--light-gold-secondary);
    }
`;
    
export default PrimaryButton
