import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Breadcrumb from "../components/Breadcrumb";
import AboutContent from "../components/AboutContent";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const About = () => {
  const [crumbs, setCrumbs] = useState(["Home", "About"]);

  const selected = (crumb) => {
    console.log(crumb);
  };
  return (
    <>
      <Helmet>
        <title>About Us | Precision Metal Spinning Experts</title>
        <meta
          name="description"
          content="Learn more about our company, the unrivaled experts in precision metal spinning. With a relentless focus on quality, accuracy, and abundance, we bring decades of experience and a passion for craftsmanship to every project. Discover how our dedication to excellence sets us apart in the industry."
        />
      </Helmet>

      <Navbar />
      <AboutContent />
      <Footer />
    </>
  );
};

export default About;
