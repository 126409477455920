

export const MenuItems = [
    {   id: 1,
        title: 'Dyes',
        path: '/dyes',
        cName: 'dropdown-link'
    },
    {   id: 2,
        title: 'Spinning',
        path: '/spinning',
        cName: 'dropdown-link'
    },
    {
        id: 3,
        title: 'Defence',
        path: '/defence',
        cName: 'dropdown-link'
    },
    {
        id: 4,
        title: 'Tools',
        path: '/tools',
        cName: 'dropdown-link'
    },
    {
        id: 5,
        title: 'Railway Parts',
        path: '/railwayparts',
        cName: 'dropdown-link'
    },
    {
        id: 6,
        title: 'Special',
        path: '/specialpurpose',
        cName: 'dropdown-link'
    },
    {
        id: 7,
        title: 'All Metal Parts',
        path: '/allMetalpart',
        cName: 'dropdown-link'
    },
    {
        id: 8,
        title: 'Auto Parts',
        path: '/autoparts',
        cName: 'dropdown-link'
    },
    {
        id: 9,
        title: 'Deep Draw',
        path: '/deepdraw',
        cName: 'dropdown-link'
    },
    {
        id: 10,
        title: 'Electrical Lamp',
        path: '/electricallamp',
        cName: 'dropdown-link'
    },
    {
        id: 11,
        title: 'Exhaust Fan',
        path: '/exhaustfan',
        cName: 'dropdown-link'
    },
    {
        id: 12,
        title: 'Fabrication',
        path: '/fabrication',
        cName: 'dropdown-link'
    },
    {
        id: 13,
        title: 'Geyser Tank',
        path: '/geyserinnertank',
        cName: 'dropdown-link'
    },
    {
        id: 14,
        title: 'Inhouse Products',
        path: '/inhouseproducts',
        cName: 'dropdown-link'
    },
    {
        id: 15,
        title: 'Pipes Embossing Reduce',
        path: '/pipesembossingreduce',
        cName: 'dropdown-link'
    },
    {
        id: 16,
        title: 'Specialty',
        path: '/specialty',
        cName: 'dropdown-link'
    }
];












