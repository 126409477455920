import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import PrimaryButton from './PrimaryButton';
import { FiAlignRight, FiXCircle, FiChevronDown, GoThreeBars } from "react-icons/fi";
import { FaBars } from "react-icons/fa";
import { MenuItems } from './MenuItems';
import './Dropdown.css';


const Dropdown = () => {
    const [showdown, setShowdown] =useState(false);

    return (
        <>
            <ul onClick={() => setShowdown(showdown)} className={showdown ? 'showdown-menu clicked' : 'showdown-menu'}>
                {MenuItems.map((item) => {
                    return (
                        <li key={item.id}>
                            <Link className={item.cName} to={item.path} onClick={() => setShowdown(false)}>
                                <a>{item.title}</a> <hr style={{color: "white"}} />
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

export default Dropdown
 