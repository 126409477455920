import React from 'react'
import Footer from '../components/Footer'
import AutoPartsContent from '../components/product/AutoPartsContent'
import Navbar from '../components/Navbar'
import { Helmet } from 'react-helmet'
const AutoParts = () => {
    return (
        <>
            <Helmet>
            <title>Auto Parts Manufacturing Excellence | Precision Metal Components for Vehicles</title>
            <meta name='description' content='Experience manufacturing excellence with our comprehensive range of precision metal auto parts. As industry leaders, we specialize in crafting high-quality, accurate components that meet the stringent demands of the automotive sector. From engines to chassis, rely on our expertise to deliver exceptional craftsmanship, reliability, and an abundance of options for your automotive manufacturing needs.' />
        </Helmet>
        <Navbar />
       <AutoPartsContent />
       <Footer />
       </>

    )
}

export default AutoParts
