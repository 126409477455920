import React from 'react'
import Common from './Common';
import spinning from '../../img/spinning.jpg'
import './Product.css'

const SpinningContent = () => {
    return (
        <>
            <Common 
            title='Spinning'
            description='We do Spinning Parts Manufacturing and tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={spinning}
            />
        </>
    )
}

export default SpinningContent






