import React from 'react'
import MediaSectionStyle from './MediaSectionStyle'
import vidimg from '../img/vidimg.png'
import showcase from '../img/showcase.jpeg'
import compnyimg from '../img/compnyimg.jpeg'
import companyimg2 from '../img/companyimg2.jpeg'
import companyimg3 from '../img/companyimg3.jpeg'
import companyimg4 from '../img/companyimg4.jpeg'

const MediaSection = () => {
    return (
      <MediaSectionStyle>
        <div>
          <div class="container">
            <div class="row">
              <div className="secondary-heading">
                <h2>Media</h2>
              </div>
              <div class="col-md-12 mt-5">
                <div class="row">
                  <div class="col-md-6">
                    <figure class="pic-hover hover-scale mb30">
                      <span class="center-xy">
                        <a
                          class="popup-youtube"
                          href="https://youtu.be/0_gfJ-IMByU">
                          <i class="fa fa-play btn-action btn-play btn-action-hide"></i>
                        </a>
                      </span>
                      <span class="bg-overlay"></span>
                      <img src={vidimg} class="img-responsive" alt="" />
                    </figure>
                  </div>
                  <div class="col-md-6">
                    <figure class="pic-hover hover-scale mb30">
                      <span class="bg-overlay"></span>
                      <img src={showcase} class="img-responsive" alt="" />
                    </figure>
                  </div>
                  <div class="col-md-6">
                    <figure class="pic-hover hover-scale mb30">
                      <span class="bg-overlay"></span>
                      <img src={compnyimg} class="img-responsive" alt="" />
                    </figure>
                  </div>
                  <div class="col-md-6">
                    <figure class="pic-hover hover-scale mb30">
                      <span class="bg-overlay"></span>
                      <img src={companyimg2} class="img-responsive" alt="" />
                    </figure>
                  </div>
                  <div class="col-md-6">
                    <figure class="pic-hover hover-scale mb30">
                      <span class="bg-overlay"></span>
                      <img src={companyimg3} class="img-responsive" alt="" />
                    </figure>
                  </div>
                  <div class="col-md-6">
                    <figure class="pic-hover hover-scale mb30">
                      <span class="bg-overlay"></span>
                      <img src={companyimg4} class="img-responsive" alt="" />
                    </figure>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </MediaSectionStyle>
    );
}

export default MediaSection
