import React from 'react'
import Footer from '../components/Footer'
import DeepDrawContent from '../components/product/DeepDrawContent'
import Navbar from '../components/Navbar'
import { Helmet } from 'react-helmet'

const DeepDraw = () => {
    return (
        <>
        <Helmet>
        <title>Deep Draw Metal Part Tooling | Unmatched Precision and Manufacturing Excellence</title>
        <meta name='description' content='Discover our expertise in deep draw metal part tooling, delivering unparalleled precision and manufacturing excellence. As leaders in the industry, we specialize in creating high-quality, intricate components using advanced deep draw techniques. Trust our experienced team to provide exceptional craftsmanship, accurate results, and a wide range of options to fulfill your deep draw metal part tooling requirements.' />
    </Helmet>
            <Navbar />
            <DeepDrawContent />
            <Footer />
        </>
    )
}

export default DeepDraw
