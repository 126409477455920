import React from 'react'
import styled from 'styled-components'

const SecondaryButton = ({name}) => {
    return (
        <SecondaryButtonStyled>
            {name}
        </SecondaryButtonStyled>
    )
}

const SecondaryButtonStyled = styled.button`
    cursor: pointer;
    padding: 1rem 3rem;
    border-radius: 2rem;
    background-color: var(--light-gold);
    outline: none;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: inherit;
    border: lighter;

    :hover {
        padding: 0.9rem 2.8rem;
        transition: all 0.3s ease-out;
        background-color: red;
        color: #fff;
        border-radius: 2rem;
        border: 2px solid var(--light-gold-secondary);
    }
    

`;

export default SecondaryButton
