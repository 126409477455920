import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SpecialPurposeDevelopmentContent from "../components/product/SpecialPurposeDevelopmentContent";
import { Helmet } from "react-helmet";

const SpecialPurposeDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>
        Special Purpose Development | Tailored Solutions for Unique Challenges
        </title>
        <meta
          name="description"
          content="Explore our special purpose development services, offering customized solutions for unique challenges. With our expertise, we specialize in developing innovative and purpose-built solutions to meet specific industry needs. Trust our experience and commitment to deliver reliable craftsmanship and tailored solutions that address your specialized requirements."
        />
      </Helmet>
      <Navbar />
      <SpecialPurposeDevelopmentContent />
      <Footer />
    </>
  );
};

export default SpecialPurposeDevelopment;
