import React, {useState} from 'react'
import './Product.css'
import Common from './Common';
import electricalLamp from '../../img/electricalLamp.jpg'

const ElectricalLampContent = () => {
    return (
            
        <>
            <Common 
            title='Electrical Lamp Parts Manufacturing'
            description='Electrical Lamp Parts tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={electricalLamp}
            />
        </>
    )
}

export default ElectricalLampContent
