import React from "react";
import Navbar from "../components/Navbar";
import DefenceContent from "../components/product/DefenceContent";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const Defence = () => {
  return (
    <>
      <Helmet>
        <title>
        Defence Parts Manufacturing | Superior Quality and Precision Engineering
        </title>
        <meta
          name="description"
          content="Explore our defense parts manufacturing services, known for delivering superior quality and precision engineering. As a trusted provider, we specialize in producing critical components for defense applications, ensuring reliability, durability, and adherence to strict standards. Rely on our expertise to meet your defense industry requirements with exceptional craftsmanship and unwavering commitment to excellence."
        />
      </Helmet>
      <Navbar />
      <DefenceContent />
      <Footer />
    </>
  );
};

export default Defence;
