import React from 'react'
import Common from './Common';
import allMetalParts from '../../img/allMetalParts.jpg';
import './Product.css'


const AllMetalPartContent = () => {
    return (
        <>
            
        <Common 
            title='Welcome to all metal parts page'
            description='Any metal part tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={allMetalParts}
         />
        
        </>
    )
}

export default AllMetalPartContent
