import styled, { css } from "styled-components";
import autoParts from '../img/autoParts.jpg'

const ProductContentStyled = styled.section`
  margin: 15rem 5rem;
  @media screen and (max-width: 550px) {
    margin: 15rem 2rem;
  }
  .product-card-body {
    background-color: #d2dbdd;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: center;
    -webkit-align-items: center;
    justify-content: center;
    align-items: center;
  }
  .cards {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow: hidden;
  }

  .card--1 .card__img,
  .card--1 .card__img--hover {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
  }

  .card__like {
    width: 18px;
  }

  .card__clock {
    width: 15px;
    vertical-align: middle;
    fill: #ad7d52;
  }
  .card__time {
    font-size: 12px;
    color: #ad7d52;
    vertical-align: middle;
    margin-left: 5px;
  }

  .card__clock-info {
    float: right;
  }

  .card__img {
    visibility: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 235px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .card__info-hover {
    position: absolute;
    padding: 16px;
    width: 100%;
    opacity: 0;
    top: 0;
  }

  .card__img--hover {
    transition: 0.2s all ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    height: 235px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    top: 0;
  }
  .card {
    margin-right: 25px;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
    background-color: #fff;
    width: 33.3%;
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 13px 10px -7px rgba(0, 0, 0, 0.1);
  }
  .card:hover {
    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0, 0.1);
    transform: scale(1, 1);
  }

  .card__info {
    z-index: 2;
    background-color: #fff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 16px 24px 24px 24px;
  }

  .card__category {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 2px;
    font-weight: 500;
    color: #868686;
  }

  .card__title {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .card__by {
    font-weight: 500;
  }

  .card__product-link {
    font-weight: 600;
    text-decoration: none;
    color: #ff0000;
    font-size: 1.4rem;
    background-color: var(--light-gold-secondary);
    padding: 1rem 1rem;
    border-radius: 0.5rem;
  }
  .card__product-link:hover {
    background-color: rgb(253, 252, 242);
    border: 1px solid red;
  }

  .card:hover .card__img--hover {
    height: 70%;
    opacity: 0.3;
  }

  .card:hover .card__info {
    background-color: transparent;
    position: relative;
  }

  .card:hover .card__info-hover {
    opacity: 1;
  }

  @media screen and (max-width: 990px) {
    .cards {
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-justify-content: center;
      align-items: center;
      padding: 2rem 1rem;
      max-width: 100%;
      height: 100%;
    }
    .card {
      background-color: #fff;
      width: 60%;
      margin-right: 0px;
      padding: 5rem;
      height: 100%;
    }

    .card__img--hover img {
      margin-top: 10rem;
      width: 70%;
    }
    .card__info {
      margin-top: 5rem;
      z-index: 2;
      background-color: #fff;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      padding: 30px 24px 24px 24px;
    }
  }
  @media screen and (max-width: 550px) {
    .cards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      -webkit-justify-content: center;
      align-items: center;
      padding: 2rem 1rem;
      max-width: 100%;
    }

    .card {
      transition: all 0.4s cubic-bezier(0.175, 0.885, 0, 1);
      background-color: #fff;
      width: 100%;
      padding: 2rem;
    }
    .product-card-body {
      background-color: #d2dbdd;

      display: flex;
      display: -webkit-flex;
      -webkit-justify-content: center;
      -webkit-align-items: center;
      justify-content: center;
      align-items: center;
    }
    .card__img--hover img {
      margin-top: 5rem;
      width: 100%;
    }
    .card__info {
      margin-top: 1rem;
      padding: 10px 10px 10px 10px;
      width: 100%;
    }
  }
`;

export default ProductContentStyled