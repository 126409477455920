import React from 'react'
import Common from './Common';
import specialPurpodeDevelopment from '../../img/specialPurpodeDevelopment.jpg'
import './Product.css'

const SpecialPurposeDevelopmentContent = () => {
    return (
        <>
            <Common 
            title='Special Purpose Development'
            description='We do Special Purpose Development of any metal parts and tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={specialPurpodeDevelopment}
            />
        </>
    )
}

export default SpecialPurposeDevelopmentContent
