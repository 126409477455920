import React from 'react'
import Navbar from '../components/Navbar'
import ElectricalLampContent from '../components/product/ElectricalLampContent'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet'



const ElectricalLamp = () => {
    return (
        <>
        <Helmet>
        <title>
        Electrical Lamp Parts Manufacturing | Exceptional Quality and Custom Solutions
        </title>
        <meta
          name="description"
          content="Discover our electrical lamp parts manufacturing services, renowned for delivering exceptional quality and custom solutions. As industry experts, we specialize in producing a wide range of precision components for electrical lamps, ensuring durability, efficiency, and seamless integration. Count on our expertise to provide reliable craftsmanship and tailored solutions for all your electrical lamp manufacturing needs."
        />
      </Helmet>
        <Navbar />
        <ElectricalLampContent />
        <Footer />
    </>


    )
}

export default ElectricalLamp
