import React from 'react'
import styled from 'styled-components';
import Contactus from '../components/Contactus'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { InnerLayout, OuterLayout } from '../styles/Layout';
import { Helmet } from 'react-helmet';

const Contact = () => {
    return (
        <ContactStyled>
          <Helmet>
            <title>Contact</title>
            <meta name='description' content='Contact page off abundance system' />
        </Helmet>
            <Navbar />
            <OuterLayout>
           <Contactus />
           </OuterLayout>
            <Footer />
        
        </ContactStyled>
    )
};


const ContactStyled = styled.section`
   
    

`;
    



export default Contact


