import React from 'react'
import Common from './Common';
import pipesEmbossingreduce from '../../img/pipesEmbossingreduce.jpg'
import './Product.css'


const PipesembossingReduceContent = () => {
    return (
        <>
        <Common 
        title='Pipes Embossing and Reduce'
        description='We do Pipes Embossing and Reduce Any metal part tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
        imgsrc={pipesEmbossingreduce}
        />
        
        </>
    )

}

export default PipesembossingReduceContent
