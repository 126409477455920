import React, {useState} from 'react'
import './Product.css'
import deepdraw from '../../img/deepdraw.jpg';
import Common from './Common';


const DeepDrawContent = () => {
   
        
    return (
        <> 
                    
            <Common 
            title='Special Deep Draw'
            description='Any Deep Draw metal part tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={deepdraw}
            />         
        
        </>
    )
}

export default DeepDrawContent
