import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import DyesContent from "../components/product/DyesContent";
import { Helmet } from "react-helmet";

const Dyes = () => {
  return (
    <>
      <Helmet>
        <title>
        Dies and Parts Manufacturing | Precision Craftsmanship and Reliable Solutions
        </title>
        <meta
          name="description"
          content="Explore our dies and parts manufacturing services, known for precision craftsmanship and reliable solutions. As industry experts, we specialize in manufacturing high-quality dies and parts for various applications, ensuring durability, accuracy, and superior performance. Trust our expertise to deliver exceptional results and tailored solutions for all your dies and parts manufacturing needs."
        />
      </Helmet>
      <Navbar />
      <DyesContent />
      <Footer />
    </>
  );
};

export default Dyes;
