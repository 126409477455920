import React from 'react'
import Common from './Common';
import drawdies from '../../img/drawdies.jpg'
import './Product.css';

const DyesContent = () => {

    return (
        <>
        <Common 
        title='Dies and Parts Manufacturing'
        description='All types of Dies and Parts tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
        imgsrc={drawdies}
        />
        
        </>
    )
}

export default DyesContent
