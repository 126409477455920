import React from 'react'
import styled from 'styled-components';

const Breadcrumb = (props) => {

    function isLast(index) {
        return index === props.crumbs.length - 1;
    }
    return (
        <BreadcrumbStyled >
            <nav className="row justify-content-center">
                <ol className="breadcrumb">
                    {
                        props.crumbs.map((crumb, ci) => {
                            const disabled = isLast(ci) ? 'disabled' : '';
                            return (
                                <li
                                    key={ ci }
                                    className='breadcrumb-item align-items-center'
                                >
                                    <button className={ `btn btn-link ${ disabled }` } onClick={ () => props.selected(crumb) } >
                                        { crumb }
                                    </button>
                                </li>
                            );
                        })
                    }
                </ol>
            </nav>
        </BreadcrumbStyled>
    )
}



const BreadcrumbStyled = styled.section`
    background-color: #ff0000;
    margin-top: 5rem;
    padding: 2rem 0;
    border-top: 2px solid #fff;
    .btn {
        background: #fff;
        color: #ff0000;
        padding: 1.5rem 2.3rem;
        margin: 1.3rem 0;
    }
    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: .5rem;
        color: #fff;
        content: "/";
    }
    .breadcrumb {
        background-color: #ff0000;
        border: none;
        border-radius: 0.37rem;
    }
    @media screen and (max-width: 550px){
        .breadcrumb {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;
export default Breadcrumb
