import React from 'react'
import styled from 'styled-components'
import arrow from '../img/arrow.svg'
import blob1 from '../img/blob_top.svg';
import blob2 from '../img/blob_bottom.svg';

const AnimationButton = ({name}) => {
    return (
        <AnimationButtonStyled>
            {name}
            <img src={arrow} className="arrow" alt="" />
            <img src={blob1} alt="" className="blob1" />
            <img src={blob2} alt="" className="blob2" />
            
        </AnimationButtonStyled>
    )
}

const AnimationButtonStyled = styled.button`
    background-color: var(--light-gold);
    padding: 1rem 2.4rem;
    font-family: inherit;
    font-size: 1.8rem;
    color: dark-gray;
    border-radius: 2rem;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    position: relative; 
    transition: all .4s ease-in-out;
    .arrow {
        padding-left: .9rem;
        transition: all .4s ease-in-out;
    }
    &:hover {
        color: var(--red-cherry);
        transition: all .4s ease-in-out;
        .blob1 {
            transition: all .4s ease-in-out;
            transform: translateX(-70px);
        }
        .blob2 {
            transition: all .4s ease-in-out;
            transform: translateX(60px);
        }

        .arrow {
            padding-left: 1.8rem;
            transition: all .4s ease-in-out;
        }
    }
    .blob1, .blob2 {
        position: absolute;
        pointer-events: none;
        transform: all 0.8s ease-in-out;
    }
    
    .blob1 {
        top: 0;
        right: 0;
    }
    .blob2 {
        bottom: 0;
        left: 1;
    }
`;

export default AnimationButton
