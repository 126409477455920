import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import SpinningContent from "../components/product/SpinningContent";
import { Helmet } from "react-helmet";

const Spinning = () => {
  return (
    <>
      <Helmet>
        <title>
          Specialty in Metal Spinning Tools and Job Work | Precision
          Craftsmanship and Exceptional Service
        </title>
        <meta
          name="description"
          content="Discover our specialty in metal spinning tools and job work, providing precision craftsmanship and exceptional service. As experts in the field, we offer a wide range of metal spinning tools and job work services, ensuring accurate and high-quality results. Count on our specialized knowledge and commitment to deliver superior craftsmanship for all your metal spinning needs."
        />
      </Helmet>
      <Navbar />
      <SpinningContent />
      <Footer />
    </>
  );
};

export default Spinning;
