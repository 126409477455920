import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import GeyserInnertankContent from "../components/product/GeyserInnertankContent";
import { Helmet } from "react-helmet";

const GeyserInnertank = () => {
  return (
    <>
      <Helmet>
        <title>
        Geyser Inner Tank Manufacturing | Quality, Durability, and Efficiency
        </title>
        <meta
          name="description"
          content="Discover our expertise in geyser inner tank manufacturing, offering top-quality, durable, and efficient solutions. As specialists in the field, we craft precision-engineered inner tanks for geysers, ensuring optimal performance, longevity, and superior heat retention. Trust our manufacturing excellence to provide reliable craftsmanship and tailored solutions for your geyser production needs."
        />
      </Helmet>
      <Navbar />
      <GeyserInnertankContent />
      <Footer />
    </>
  );
};

export default GeyserInnertank;
