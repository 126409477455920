import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import PrimaryButton from './PrimaryButton';
import {  FiChevronDown } from "react-icons/fi";
import { FaBars } from "react-icons/fa";
import { Fade } from 'react-reveal';
import logo from '../img/logo.svg'
import Dropdown from './Dropdown';
import './Navbar.css'


const Navbar = () => {
  const [dropdown, setDropdown] = useState(false);


  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click); 

  const closeMobileMenu = () => setClick(false);
 
          return (
            <>
              <nav style={{position:"sticky",top:"0px",padding:"0px",zIndex:"2"}} className="navbar ">
                <div className="navbar__body-web">
                <Link to="/" className="navbar-logo">
                  <img src={logo} alt="" />
                </Link>
                <div className="menu-icon" onClick={handleClick}>
                  <i className={click ? "fas fa-times" : "fas fa-bars"} />
                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="nav-links"
                      onClick={closeMobileMenu}>
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/about"
                      className="nav-links"
                      onClick={closeMobileMenu}>
                      about
                    </Link>
                  </li>
                  <li
                    className="nav-item"
                    onMouseEnter={() => setDropdown(true)}
                    onMouseLeave={() => setDropdown(false)}>
                    <Link
                      to="/products"
                      className="nav-links"
                      onClick={closeMobileMenu}>
                      Products <FiChevronDown />
                    </Link>
                    {dropdown && <Dropdown />}
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/contact"
                      className="nav-links"
                      onClick={closeMobileMenu}>
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="nav-links-mobile"
                      onClick={closeMobileMenu}>
                      Get In Touch
                    </Link>
                  </li>
                </ul>
                <PrimaryButton name={"Get in Touch"} />
                </div>
              </nav>
            </>
          );
};





export default Navbar

