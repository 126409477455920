import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import RailwayPartsContent from "../components/product/RailwayPartsContent";
import { Helmet } from "react-helmet";

const RailwayParts = () => {
  return (
    <>
      <Helmet>
        <title>
          Railway Parts Manufacturing | Robust Solutions for Safe and Reliable
          Rail Systems
        </title>
        <meta
          name="description"
          content="Discover our comprehensive railway parts manufacturing services, providing robust solutions for safe and reliable rail systems. As industry leaders, we specialize in producing high-quality and precision-engineered parts for railway applications, ensuring adherence to strict safety standards and efficient rail operations. Rely on our manufacturing excellence to deliver superior craftsmanship and dependable railway components."
        />
      </Helmet>
      <Navbar />
      <RailwayPartsContent />
      <Footer />
    </>
  );
};

export default RailwayParts;
