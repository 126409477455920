import React from 'react'
import Common from './Common';
import fabrication from '../../img/fabrication.jpg'
import './Product.css';


const FabricationContent = () => {
    return (
        <>
        <Common 
        title='Fabrication Work'
        description='All types of fabrication work, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
        imgsrc={fabrication}
        />
        
        </>
    )
}

export default FabricationContent
