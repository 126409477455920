import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import ProductContent from '../components/ProductContent'
import { Helmet } from 'react-helmet'

const Products = () => {
    return (
        <div>
            <Helmet>
        <title>All Products</title>
        <meta
          name="description"
          content="Know more about all products"
        />
      </Helmet>
            <Navbar />
            <ProductContent />
            <Footer />
        </div>
    )
}

export default Products
