import React, {useState} from 'react'
import './Product.css'
import Common from './Common';
import exhaustFan from '../../img/exhaustFan.jpg'


const ExhaustFanContent = () => {
    return (
            
        <>
            <Common 
            title='Exhaust Fan Parts Manufacturing'
            description='Exhaust Fan Parts tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={exhaustFan}
            />
        </>
    )
}

export default ExhaustFanContent
