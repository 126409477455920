import styled from "styled-components";

const MediaSectionStyle = styled.div`
  position: relative;
  box-shadow: 0px 25px 50px rgba(22, 25, 79, 0.05);
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.1);
  overflow: hidden;
  justify-content: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 2rem 4rem;

  .secondary-heading h2 {
    font-size: 3.6rem;
  }

  .bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .pic-hover .bg-overlay,
  > .bg-overlay,
  i.btn-action-hide,
  .pic-hover.hover-scale img {
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  .pic-hover {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .pic-hover:hover {
    -o-transition: 0.5s;
    -ms-transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
  }
  .center-xy {
    position: absolute;
    z-index: 5;
  }
  .img-responsive {
    display: inline-block;
  }

  .mb30 {
    margin-bottom: 30px;
  }
  .feature-img {
    width: 83%;
    margin: auto;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
  }

  /* misc begin */

  .popup-youtube {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-youtube i {
    position: absolute;
    top: 10rem;
    left: 20rem;
    justify-content: center;
  }
  .color-overlay {
    background: rgba(0, 0, 0, 0.7);
  }
  .color-overlay-2 {
    background: rgba(26, 37, 48, 0.85);
  }

  .fontsize24 {
    font-size: 24px !important;
  }
  .fontsize32 {
    font-size: 32px !important;
  }
  .fontsize36 {
    font-size: 36px !important;
  }
  .fontsize40 {
    font-size: 40px !important;
  }
  .fontsize48 {
    font-size: 48px !important;
  }

  .padding30 {
    padding: 30px !important;
  }
  .padding40 {
    padding: 40px !important;
  }
  .padding50 {
    padding: 50px !important;
  }
  .padding60 {
    padding: 60px !important;
  }
  .padding70 {
    padding: 70px !important;
  }
  .padding80 {
    padding: 80px !important;
  }

  .hidden-xy {
    overflow: hidden;
  }

  .bg-black-60 {
    background: rgba(0, 0, 0, 0.6);
  }
  .bg-black-70 {
    background: rgba(0, 0, 0, 0.7);
  }
  .bg-black-80 {
    background: rgba(0, 0, 0, 0.8);
  }

  #services-list {
    padding: 0;
    margin: 0;
  }

  #services-list li a {
    color: #fff;
  }

  #services-list li.active a:after {
    font-family: "FontAwesome";
    content: "\f054";
    float: right;
  }

  .de_light #services-list li a {
    color: #999;
  }

  #services-list li {
    font-weight: bold;
    list-style: none;
    margin: 0;
    background: rgba(0, 0, 0, 0.3);
    margin-bottom: 2px;
  }

  .de_light #services-list li {
    background: #eee;
  }

  #services-list li a {
    display: block;
    padding: 15px 20px 15px 20px;
    text-decoration: none;
  }

  #services-list li a:hover {
    color: #111;
  }

  .pic-services img {
    margin-bottom: 30px;
  }

  #services-list li.active a,
  #services-list li.active a:hover {
    color: #fff;
    background: #333;
  }

  .project-images img {
    margin-bottom: 60px;
  }

  .teaser-text {
    font-family: "Montserrat";
    font-size: 18px;
    color: rgba(255, 255, 255, 0.5);
    line-height: normal;
    font-weight: 300;
  }

  .teaser-text h2 {
  }

  #navigation {
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    right: 20px;
    text-align: center;
    margin-bottom: 60px;
    z-index: 1000;
  }

  .nav-prev:before,
  .nav-next:before,
  .nav-exit:before {
    font-family: "FontAwesome";
    content: "\f177";
    color: #fff;
    padding: 20px;
    font-size: 24px;
    cursor: pointer;
  }

  .nav-exit:before {
    width: 100%;
    display: block;
  }

  .nav-next:before {
    content: "\f178";
    width: 100%;
    background: #555;
  }

  .nav-exit:before {
    content: "\f00d";
  }

  .de_light .nav-exit:before {
    color: #222;
  }

  .container-4 .de-team-list {
    width: 24.9%;
    float: left;
    position: relative;
  }

  .container-3 .de-team-list {
    width: 33.3%;
    float: left;
    position: relative;
  }

  .no-bottom {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    margin-bottom: 20px;
  }

  p.intro {
    color: #555;
    font-size: 16px;
    line-height: 1.5em;
  }

  .bg-grey {
    background: #f5f5f5;
  }

  .no-bottom .col-md-12 {
  }

  .no-bottom {
    padding-bottom: 0 !important;
  }
  .no-top {
    padding-top: 0 !important;
  }
  .no-margin {
    margin: 0 !important;
  }

  .nopadding {
    padding: 0 !important;
    margin: 0 !important;
  }

  #filters a {
    font-family: "Montserrat";
    background: none;
  }

  #filters i {
    margin-right: 10px;
  }

  #filters li:last-child a {
    padding-right: 0;
    margin-right: 0;
  }

  .bg-side {
    position: relative;
    overflow: hidden !important;
  }

  .image-container {
    position: absolute;
    padding: 0;
    margin: 0 !important;
    background-size: cover !important;
  }

  .image-slider {
    cursor: move;
  }

  .inner-padding {
    padding: 90px 0 90px 0;
  }

  .no-padding,
  section.no-padding {
    padding: 0;
  }

  .float-left {
    float: left;
  }

  #loader-area {
    display: none;
    background: #fff;
  }

  #loader-area {
    display: none;
    background: #111;
  }

  .loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: url("../images/page-loader.gif") 50% 50% no-repeat
      rgb(249, 249, 249);
  }

  .page-overlay {
    display: none;
    width: 120px;
    height: 120px;
    background: url(../images/page-loader.gif) center no-repeat #fff;
    border-radius: 10px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
    margin: auto;
  }

  .teaser {
    font-size: 15px;
    font-weight: 300;
  }

  .form-control:focus {
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
  }

  .owl-arrow {
    position: absolute;
    z-index: 100;
    background: #fff;
    margin-top: 48%;
    display: none;
    cursor: pointer;
  }
  .owl-arrow span {
    display: block;
    background: #555;
    position: absolute;
  }
  .owl-arrow .prev {
    left: 0;
  }
  .owl-arrow .next {
    right: 0;
  }

  .owl-arrow .prev:before,
  .owl-arrow .next:before {
    font-family: "FontAwesome";
    content: "\f177";
    color: #fff;
    padding: 20px;
    font-size: 32px;
    display: block;
  }
  .owl-arrow .next:before {
    content: "\f178";
  }

  .owl-theme .owl-controls .owl-page span {
    width: 5px !important;
    height: 5px !important;
  }

  .owl-pagination {
    margin-top: 0px;
  }

  .owl-theme .owl-controls .owl-page span {
    background: #ddd;
    width: 5px;
    height: 5px;
    opacity: 1;
  }

  .text-slider {
    color: #fff;
    display: inline-block;
  }

  .text-slider.med-text {
    font-size: 72px;
    line-height: 84px;
    font-weight: bold;
  }

  .text-slider.big-text {
    font-size: 120px;
    line-height: 120px;
    font-weight: bold;
  }

  .border-deco .text-item {
    display: inline-block;
  }

  .text-slider.dark {
    color: #222;
  }

  .text-item i {
    font-size: 60px;
    margin-top: 5px;
  }

  .text-slider.big-text i {
    font-size: 90px;
    margin-top: 15px;
  }

  .text-slider h1 {
    font-size: 60px;
  }

  .text-rotate-wrap {
    display: inline-block;
  }

  .text-rotate-wrap .text-item {
    display: none;
  }

  .red {
    color: #ff0000 !important;
  }
  .teal {
    color: #009999 !important;
  }
  .purple {
    color: #663366 !important;
  }
  .green {
    color: #009900 !important;
  }

  .bg-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .pic-hover {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  i.btn-action-hide {
    opacity: 0;
  }

  .pic-hover:hover .bg-overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .pic-hover:hover i.btn-action-hide {
    opacity: 1;
  }

  .pic-hover.hover-scale:hover img {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }

  .center-xy {
    position: absolute;
    z-index: 5;
  }

  .center-xy i.btn-action {
    font-size: 24px;
    border: solid 2px rgba(255, 255, 255, 0.3);
    padding: 16px;
    width: 60px;
    height: 60px;
    border-radius: 60px;
    text-align: center;
    color: #fff;
  }

  .center-xy i.btn-action:hover {
    border-color: rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0.3);
  }

  .center-xy i.btn-action.btn-play {
    padding-left: 20px;
  }

  .bg-grey,
  section.bg-grey {
    background: #f8f8f8;
  }
  .wow {
    visibility: hidden;
  }

  #back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 1020;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    background: #f5f5f5;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    outline: none;
  }
  #back-to-top:hover {
    background: #111;
  }
  #back-to-top.show {
    opacity: 1;
  }
  #back-to-top:before {
    font-family: "FontAwesome";
    font-size: 14px;
    content: "\f176";
    color: #222;
    position: relative;
    margin: 5px;
  }
`;

export default MediaSectionStyle;
