import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import logo from "./../../img/logo.svg";
import product from "./../../img/parts-manufacturing.png";
import product1 from "./../../img/pipesembossing.png";
import product2 from "./../../img/allmetalparts.png";
import product3 from "./../../img/spinningtools.png";
import product4 from "./../../img/fabricationwork.png";
import product5 from "./../../img/tools-03.png";
import product6 from "./../../img/special-deep-draw.png";
import spinning from "./../../img/spinning-job-work.png";

import product7 from "./../../img/l2.png";
import product8 from "./../../img/landing2.png";
import product9 from "./../../img/landing3.png";
import product10 from "./../../img/landing4.png";
import product11 from "./../../img/landing5.png";

import exhaust from "./../../img/exhaustFan.jpg";
import autoparts from "./../../img/autoParts.jpg";
import fabrication from "./../../img/fabrication.jpg";
import special from "./../../img/speciality.jpg";

import Contactus from "../../components/Contactus";
import { FiArrowRight } from "react-icons/fi";
import SimpleSlider, { ContactUs } from "./SimpleSlider";
import { useState } from "react";
import { message } from "antd";
import "./style2.css";

export default function Landingcore() {
  const form = useRef();
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_phone: "",
    message: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_hghnbif",
        "template_zyvrn7w",
        form.current,
        "n7Stmp1rYvd38e1li"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("sendd");
          message.success("Email Send Successfull");
          setFormData({
            user_name: "",
            user_email: "",
            user_phone: "",

            message: "",
          });
        },
        (error) => {
          console.log(error.text);
          message.error("Email Send Unsuccessfull");

          setFormData({
            user_name: "",
            user_email: "",
            user_phone: "",

            message: "",
          });
        }
      );
  };

  return (
    <>
      <section
        id="slider"
        style={{ paddingTop: "0px" }}
        class="section slider landing-section">
        <div class="slide--item bg-theme">
          <div class="container landing-con">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12">
                <div
                  class="slide--logo mt-100 hidden-xs wow fadeInUp"
                  data-wow-duration="1s">
                  {/* <img src="assets/images/logo/logo-light.png" alt="logo hero"/> */}
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="col-xs-12 col-sm-6 col-md-6 pt-100 wow fadeInUp"
                data-wow-duration="1s">
                <div class="slide--headline">
                  <h1>Precision Manufacturing Solutions</h1>
                </div>
                <div class="slide--bio">
                  Empowering Industries with Quality Dies, Parts, and
                  Fabrication <br />
                  At Abundance Systems, we specialize in the manufacturing of
                  high-quality dies, parts, spinning parts, auto parts, defense
                  parts, and fabrication services. With our precision
                  manufacturing solutions, we empower a wide range of industries
                  with reliable and innovative components
                </div>
              </div>
              <img
                class="img-responsive pull-right landing-top-logo"
                src={product}
                alt="screens"
              />
              {/* <div class="col-xs-12 col-sm-6 col-md-4 col-md-offset-2 wow fadeInUp" data-wow-duration="1s">
						</div> */}
            </div>
          </div>
        </div>
      </section>
      <section
        id="feature2"
        class="section feature feature-2 text-center bg-white landing-section">
        <div class="container">
          <div class="rows clearfix">
            <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-80 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title">Metal Manufacturing Excellence</h2>
                <p class="heading--desc">
                  {" "}
                  Pipes, Spinning Tools, Fabrication, Auto Parts, and More
                </p>
              </div>
            </div>
          </div>
          <div class="rows mb-60">
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-users"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product5} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Tools Manufacturing</h3>
                  <p>
                    Benefit from our expertise in tool manufacturing, where we
                    design and produce custom tools that enhance productivity
                    and efficiency for a range of industries.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-cog"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product1} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Pipes Embossing and Reduce</h3>
                  <p>
                    Enhance your pipes with unique patterns and textures through
                    our expert embossing and reducing services, adding both
                    style and functionality to your projects.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-lock"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product3} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Specialty in Metal Spinning Tools and Job Work</h3>
                  <p>
                    Trust our expertise in metal spinning to deliver
                    custom-designed tools and precision job work, ensuring
                    optimal performance and superior results.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="rows">
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product4} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Fabrication Work</h3>
                  <p>
                    From small-scale projects to large-scale structures, our
                    fabrication services offer exceptional craftsmanship and
                    reliable solutions for all your metal fabrication needs.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product2} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>All Metal Parts Manufacturing</h3>
                  <p>
                    Rely on our skilled team to manufacture high-quality metal
                    components with precision and accuracy, meeting the diverse
                    needs of various industries. Spinning: Our metal spinning
                    services allow us to create complex shapes and forms,
                    providing versatile solutions for your specialized spinning
                    requirements.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product6} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Special Deep Draw</h3>
                  <p>
                    Experience exceptional precision and intricacy with our
                    special deep draw processes, producing components with
                    complex shapes and unmatched accuracy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="newsletter"
        class="section newsletter text-center bg-theme1 landing-section">
        <div class="container">
          <div class="rows clearfix" style={{ marginBottom: "40px" }}>
            <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-60 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title text-white">Contact us</h2>
              </div>
            </div>
          </div>
          <div class="rows">
            <div className="landing-form">
              <form class="" ref={form} onSubmit={sendEmail}>
                <div class="input-group">
                  <div className="m-landing">
                    <input
                      required
                      value={formData.user_name}
                      onChange={handleInputChange}
                      style={{ fontSize: "14px", marginBottom: "0px" }}
                      type="text"
                      name="user_name"
                      class="form-control input-landing"
                      placeholder="Enter Your Name"
                    />
                  </div>
                  <div>
                    <input
                      required
                      value={formData.user_email}
                      onChange={handleInputChange}
                      style={{ fontSize: "14px", marginBottom: "30px" }}
                      type="email"
                      name="user_email"
                      class="form-control input-landing"
                      placeholder="E-mail address"
                    />
                  </div>
                </div>

                <div class="input-group">
                  <input
                    required
                    value={formData.user_phone}
                    onChange={handleInputChange}
                    style={{ fontSize: "14px", marginBottom: "30px" }}
                    type="number"
                    name="user_phone"
                    class="form-control input-landing"
                    placeholder="Enter Your Phone No"
                  />
                </div>

                <div class="input-group">
                  <input
                    required
                    value={formData.message}
                    onChange={handleInputChange}
                    style={{ fontSize: "14px", marginBottom: "30px" }}
                    type="text"
                    name="message"
                    class="form-control input-landing"
                    placeholder="Query"
                  />
                </div>

                {/* <div class="input-group">
								<input style={{fontSize:"14px",marginBottom:'30px'}} type="email" class="form-control input-landing" placeholder="E-mail address"/>
							
							</div> */}
                <button type="submit" value="Send" className="submit-landing">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section
        id="reviews"
        class="section reviews bg-white landing-section"
        style={{ textAlign: "center" }}>
        <div style={{ width: "100%" }} class=" for-landing-review">
          <div class="rows clearfix">
            <div class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-60 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title">Glowing Testimonials</h2>
                <p>Hear What Our Customers Say About Metal Diyas</p>
              </div>
            </div>
          </div>
          <div class="rows landing-r-width">
            <div class=" landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      Working with Abundance Systems has been a game-changer for
                      our business. Their expertise in metal spinning tools and
                      job work helped us achieve remarkable precision in our
                      manufacturing process. Highly recommended!
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Ravi Patel, CEO</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star-half-full"></i>
                      <i class=" fa fa-star-o"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      I have been relying on Abundance Systems for all my metal
                      parts manufacturing needs, and they have never
                      disappointed. Their attention to detail and commitment to
                      quality have made them our go-to partner. Thank you for
                      the exceptional service!
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Deepika Sharma, Operations Manager</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      The team at Abundance Systems provided outstanding
                      fabrication work for our recent project. Their
                      craftsmanship and attention to detail ensured that the end
                      result exceeded our expectations. We are extremely
                      satisfied with their services.
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Vikram Singh, Project Manager</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star-half-full"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class=" landing-padding">
              <div
                class="testimonial-panel wow fadeInUp"
                data-wow-duration="1s">
                <div class="testimonial-body">
                  <div class="testimonial--body">
                    <p>
                      Abundance Systems delivered exceptional auto parts for our
                      manufacturing plant. Their expertise and adherence to
                      industry standards gave us confidence in the quality and
                      reliability of their products. We are thrilled with the
                      results!
                    </p>
                  </div>
                  <div class="testimonial--meta">
                    <div class="testimonial--author pull-left">
                      <h5>Rajesh Kumar, Production Manager</h5>
                    </div>
                    <div class="testimonial--rating pull-right">
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star"></i>
                      <i class=" fa fa-star-half-full"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <ContactUs/> */}

      <section
        id="feature2"
        class="section feature feature-2 text-center bg-white landing-section">
        <div class="container">
          <div class="rows clearfix">
            <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-80 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title">Big Spinning Job Work</h2>
                <p class="heading--desc">
                  Premium Big Spinning Job Work for Industrial and Agricultural
                  Applications
                </p>
              </div>
            </div>
          </div>
          <div class="rows mb-60">
            <div class="col-xs-12 col-sm-4 col-md-6">
              <div class="feature-panel  wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-users"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={spinning} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-6">
              <div class="feature-panel  wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-cog"></i>
                </div>
                <div class="feature--contents">
                  <p className="text-left">
                    Our company is equipped with state-of-the-art machinery and
                    a team of skilled professionals who are experienced in
                    handling big spinning projects. Whether you need large-scale
                    metal spinning for industrial components, architectural
                    elements, or decorative purposes, we have the capabilities
                    to meet your specific needs.
                  </p>
                  <p className="text-left">
                    We understand the importance of precision and accuracy in
                    big spinning job work. Our dedicated team utilizes advanced
                    techniques and tools to ensure that every piece is crafted
                    with utmost precision and attention to detail. From initial
                    design concepts to the final product, we maintain strict
                    quality control measures to deliver results that exceed your
                    expectations.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
      <section
        id="feature2"
        class="section feature feature-2 text-center bg-white landing-section">
        <div class="container">
          <div class="rows clearfix">
            <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-80 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title">Special Purpose Festival Items</h2>
                <p class="heading--desc">
                  Leading manufacturer of exquisite metallic special purpose
                  festival items in the Indian market
                </p>
              </div>
            </div>
          </div>
          <div class="rows mb-60">
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div
                class="feature-panel special-purpose wow fadeInUp"
                data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-users"></i>
                </div>
                <div class="feature--content">
                  <h3>Exquisite Craftsmanship</h3>
                  <p>
                    Our company takes pride in its exquisite craftsmanship,
                    blending traditional techniques with modern innovation to
                    create metallic special purpose festival items of
                    unparalleled beauty. Our skilled artisans pay meticulous
                    attention to detail, ensuring that each piece is a work of
                    art that reflects the rich cultural heritage of India.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div
                class="feature-panel special-purpose wow fadeInUp"
                data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-cog"></i>
                </div>
                <div class="feature--content">
                  <h3>Wide Range of Products</h3>
                  <p>
                    We offer a diverse range of metallic festival items that
                    cater to the various festivals celebrated across India. From
                    decorative diyas and puja thalis to ornamental torans and
                    deity idols, our collection is designed to enhance the
                    ambiance of your festive celebrations. We understand the
                    importance of regional and cultural nuances and incorporate
                    them into our designs, allowing you to find the perfect item
                    for your specific festival.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div
                class="feature-panel special-purpose wow fadeInUp"
                data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-lock"></i>
                </div>
                <div class="feature--content">
                  <h3>Customer Satisfaction</h3>
                  <p>
                    At our company, customer satisfaction is our top priority.
                    We are committed to delivering products of exceptional
                    quality that surpass your expectations. With a focus on
                    durability and aesthetic appeal, our festival items are
                    crafted using high-quality materials. Additionally, we
                    provide personalized customization options, ensuring that
                    you can create bespoke items that truly resonate with your
                    individual style and preferences. With our dedication to
                    excellence and attention to detail, we have earned the trust
                    of our customers nationwide.
                  </p>
                </div>
              </div>
            </div>
            <p>
              Immerse yourself in the world of our captivating metallic festival
              items, and let us bring joy, beauty, and auspiciousness to your
              special moments of celebration.
            </p>
          </div>
        </div>
      </section>
      <section
        id="feature2"
        class="section feature feature-2 text-center bg-white landing-section">
        <div class="container">
          <div class="rows clearfix">
            <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3">
              <div
                class="heading heading-1 mb-80 text--center wow fadeInUp"
                data-wow-duration="1s">
                <h2 class="heading--title">Shine Bright with Metal Diyas</h2>
                <p class="heading--desc">6 Reasons to Choose Elegance</p>
              </div>
            </div>
          </div>
          <div class="rows mb-60">
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-users"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product7} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Durability</h3>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-cog"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product8} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Exquisite Craftsmanship</h3>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <i class="lnr lnr-lock"></i>
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product9} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Timeless Appeal</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="rows">
            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product10} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Cultural Significance</h3>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product11} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Versatility</h3>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-sm-4 col-md-4">
              <div class="feature-panel wow fadeInUp" data-wow-duration="1s">
                <div class="feature--icon">
                  <div className="landing-card">
                    <img style={{ width: "300px" }} src={product7} alt="" />
                  </div>
                </div>
                <div class="feature--content">
                  <h3>Ambient Lighting </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <button className="landing-btns vissit">Visit Our Site</button>
    </>
  );
}
