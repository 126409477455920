import React from 'react'
import { Link } from 'react-router-dom'
import ProductContentStyled from './ProductContentStyle'
import autoParts from '../img/autoParts.jpg'
import spinning from '../img/spinning.jpg'
import defence from '../img/defence.jpg'
import tools from '../img/tools.jpg'
import RailwayParts from '../img/RailwayParts.jpg'
import specialPurpodeDevelopment from '../img/specialPurpodeDevelopment.jpg'
import allMetalParts from '../img/allMetalParts.jpg';
import deepdraw from '../img/deepdraw.jpg';
import electricalLamp from '../img/electricalLamp.jpg'
import exhaustFan from '../img/exhaustFan.jpg'
import fabrication from '../img/fabrication.jpg'
import geyserInnerTank from '../img/geyserInnerTank.jpg'
import inhouseProducts from '../img/inhouseProducts.jpg'
import pipesEmbossingreduce from '../img/pipesEmbossingreduce.jpg'
import speciality from '../img/speciality.jpg'

const ProductContent = () => {
    return (
      <ProductContentStyled>
        <div className="product-card-body">
          <section class="cards">
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={autoParts} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Auto Parts</h3>
                <span class="card__by">
                  <Link
                    to="/autoparts"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={spinning} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Spinning</h3>
                <span class="card__by">
                  <Link
                    to="/spinning"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>

            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={defence} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Defence Parts Manufacturing</h3>
                <span class="card__by">
                  <Link to="/defence" class="card__product-link" title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
          </section>
        </div>

        <div className="product-card-body">
          <section class="cards">
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={tools} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Tools Manufacturing</h3>
                <span class="card__by">
                  <Link to="/tools" class="card__product-link" title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={RailwayParts} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Railway Parts Manufacturing</h3>
                <span class="card__by">
                  <Link
                    to="/railwayparts"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>

            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={specialPurpodeDevelopment} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Special Purpose Development</h3>
                <span class="card__by">
                  <Link
                    to="/specialpurpose"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
          </section>
        </div>
        <div className="product-card-body">
          <section class="cards">
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={allMetalParts} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">All Metal Parts Manufacturing</h3>
                <span class="card__by">
                  <Link to="/allMetalpart" class="card__product-link" title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={deepdraw} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Special Deep Draw</h3>
                <span class="card__by">
                  <Link
                    to="/autoparts"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>

            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={electricalLamp} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Electrical Lamp Parts Manufacturing</h3>
                <span class="card__by">
                  <Link
                    to="/electricallamp"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
          </section>
        </div>
        <div className="product-card-body">
          <section class="cards">
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={exhaustFan} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Exhaust Fan Parts Manufacturing</h3>
                <span class="card__by">
                  <Link to="/exhaustfan" class="card__product-link" title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={fabrication} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Fabrication Work</h3>
                <span class="card__by">
                  <Link
                    to="/fabrication"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>

            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={geyserInnerTank} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Geyser Inner Tank Manufacturing</h3>
                <span class="card__by">
                  <Link
                    to="/geyserinnertank"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
          </section>
        </div>
        <div className="product-card-body">
          <section class="cards">
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={inhouseProducts} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Inhouse products Manufacturing</h3>
                <span class="card__by">
                  <Link to="/inhouseproducts" class="card__product-link" title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={pipesEmbossingreduce} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Pipes Embossing and Reduce</h3>
                <span class="card__by">
                  <Link
                    to="/pipesembossingreduce"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>

            <article class="card card--1">
              <div class="card__img"></div>
              <div class="card__img--hover">
                <img src={speciality} alt="autoparts" />
              </div>
              <div class="card__info">
                <span class="card__category"> Abundance Systems</span>
                <h3 class="card__title">Specialty in Metal Spinning Tools and Job Work</h3>
                <span class="card__by">
                  <Link
                    to="/specialty"
                    class="card__product-link"
                    title="product">
                    Click Here
                  </Link>
                </span>
              </div>
            </article>
          </section>
        </div>
      </ProductContentStyled>
    );
}



export default ProductContent

