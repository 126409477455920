import React from 'react'
import Common from './Common';
import tools from '../../img/tools.jpg'
import './Product.css'

const ToolsContent = () => {
    return (
        <>
            <Common 
            title='Tools Manufacturing'
            description='We manufacture all kinds of tools and we do tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
            imgsrc={tools}
            />
        </>
    )
}

export default ToolsContent
