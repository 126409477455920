import React from 'react'
import Common from './Common';
import inhouseProducts from '../../img/inhouseProducts.jpg'
import './Product.css'


const InhouseProductsContent = () => {
    return (
        <>
        <Common 
        title='Inhouse products Manufacturing'
        description='We have our own customized special products. We make diyas for festivals which you dont get anywhere, these are innovated and manufactured by us.' 
        imgsrc={inhouseProducts}
        />
        
        </>
    )
}

export default InhouseProductsContent
