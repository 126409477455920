import React from 'react'
import Common from './Common';
import geyserInnerTank from '../../img/geyserInnerTank.jpg'
import './Product.css'

const GeyserInnertankContent = () => {
    return (
        <>
        <Common 
        title='Geyser Inner Tank Manufacturing'
        description='Geyser Inner Tank tooling, cutting, dies, bending dies, draw dies, deep draw, and spinning etc...' 
        imgsrc={geyserInnerTank}
        />
        
        </>
    )
}

export default GeyserInnertankContent
