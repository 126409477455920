import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import InhouseProductsContent from '../components/product/InhouseProductsContent'
import { Helmet } from 'react-helmet'

const InhouseProducts = () => {
    return (
        <>
        <Helmet>
        <title>
        Inhouse Products Manufacturing | Customized Solutions for Your Needs
        </title>
        <meta
          name="description"
          content="Explore our inhouse products manufacturing services, delivering customized solutions tailored to your unique requirements. With our manufacturing capabilities, we create a diverse range of high-quality inhouse products, ensuring functionality, innovation, and exceptional craftsmanship. Count on us to provide reliable manufacturing expertise and meet your specific product needs efficiently."
        />
      </Helmet>
            <Navbar />
            <InhouseProductsContent />
            <Footer />
        </>
    )
}

export default InhouseProducts
