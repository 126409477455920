import React from 'react'
import styled from 'styled-components'
import { InnerLayout } from '../styles/Layout';
import { BiBullseye  } from "react-icons/bi";
import { GiBullseye  } from "react-icons/gi";
import aboutUsAp from '../API/aboutUsAp';
import abundancesystems from '../img/abundancesystems.jpg'

const CardSection = () => {
    return (
      <CardSectionStyled>
        
          <div className="card-container">
            <div className="card-left">
            <h3 className="subheading">About Us</h3>
            <h2 className="secondary-heading">
              One company for all your needs
            </h2>
              <p>
                We believe in TEAM - Together Everyone Achieves More. We are driven by passion of first success of our stake holders. Together we can achieve new heights of growth.
              </p>
              <div className="u-col">
                <div className="u-layout-row">
                  <div className="u-align-center u-container-style">
                    <div className="u-container-layout u-align-top u-container-layout-2">
                      <span className='u-icon u-icon-circle'> <i><BiBullseye className='u-icon-u' /></i></span>
                      <h3 className='u-text u-text-4'>Our Vision</h3>
                      <p className='u-text u-text-grey-50 u-text-5'>Become a master in metal spinning.</p>
                    </div>
                  </div>
                  <div className="u-align-center u-container-style">
                    <div className="u-container-layout u-valign-top u-container-layout-3">
                    <span className="u-icon u-icon-circle"><GiBullseye className='u-icon-u' /></span>
                      <h3 className='u-text u-text-6'>Our Mission</h3>
                      <p className='u-text u-text-grey-50 u-text-7'>On the path to become a master in metal spinning.</p>
                    </div>
                  </div>
                  
                </div>
              </div>
           </div>  
            <div className="card-right">
              <img src={abundancesystems} alt="card" />
            </div>
          </div>
        
      </CardSectionStyled>
    );
}

const CardSectionStyled = styled.section`
    position: relative;
    box-shadow: 0px 25px 50px rgba(22, 25, 79, 0.05);
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    justify-content: center;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2rem 4rem;
    .card-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        @media screen and (max-width: 990px){
          flex-direction: column;
        }
        h3 {
        color: #000;
        font-size: 2rem;
        line-height: 2.4rem;
    }
    p {
        
        text-align: justify;
        margin-left: 0;
        margin-bottom: 0;
        word-wrap: break-word;
    }
    
    .card-right {
      width: 100%;
      align-items: right;
      justify-content: right;
      padding: 1.5rem;
        img {
            width: 100%;
        }
        @media screen and (max-width: 990px){
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem;
          img {
            width: 100%;
            align-item: center;
          }
        }
    }

        .card-left {
          width: 100%;
          padding: 1.5rem;
            h2 {
                padding-bottom: 1rem;
            }
        }

    }   
    .u-col {
      margin-top: 2rem;
    }
    .u-container-layout {
        position: relative;
        
        flex: 1;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        
    }
    .u-layout-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2.5rem;

      span .u-icon-u {
          font-size: 5rem;
          color: red;
          width: 4rem;
        }

    }

    }  
      @media screen and (max-width: 550px){
        .u-col .u-layout-row {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 2.5rem;
        }
        .u-container-layout {
            position: relative;
            flex: 1;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .card-right {
          display: grid;
          grid-template-columns: 1fr;
          margin: 2rem;
          width: 100%;
          align-items: center;
          justify-content: center;
          
        }
      }  
`;

export default CardSection
